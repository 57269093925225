
.slick-dots li button:before {
  font-size: 10px;
  color: white;
}

.slick-dots {
  bottom: -25px; 
  display: flex !important;
  justify-content: center; 
  align-items: center;
  width: 100%; 
}

.slick-dots li {
  margin: 0 2px; 
}